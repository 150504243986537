import React from 'react'

import '../assets/scss/main.scss'

class Layout extends React.Component {
  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      document.body.classList.remove('is-preload');
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    };
  }

  render() {
    return (
      <div id="page-wrapper">
        <div id='wrapper'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Layout
